<template>
    <div class="user-follow">
        <div class="user-photo-and-nickname">
            <user-picture :picture="user.photo.url" />
            <user-nickname :user-nickname="user.displayname" />
        </div>
        <follow-entity-button
            :entity-id="user.id"
            :entity-name="user.displayname"
            :is-following="user.is_following"
            entity="users"
            @toggled-follow="(is_following) => $emit('toggled-follow', Number(is_following))"
        />
    </div>
</template>

<script>
export default {
    name: "UserFollow",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        UserNickname: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-nickname"),
        FollowEntityButton: () => import(/* webpackChunkName: "follow-user-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.user-follow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .user-photo-and-nickname {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
        margin-right: 10px;

        /deep/ .user-picture {
            figure {
                width: 27px;
                height: 27px;
            }

            margin-right: 10px;
        }

        /deep/ .user-nickname {
            font-size: 12px;
            color: #D7D9DB;
        }
    }
}
</style>
